// SCSS
import 'bootstrap';
import {
	library,
	dom,
	config
} from '@fortawesome/fontawesome-svg-core';
import {
	faPlus,
	faCheck,
	faTimes,
	faBars,
	faLightbulb,
	faHandsHelping,
	faUserCircle,
	faCloudUploadAlt,
	faFilePdf,
	faFileImage,
	faFileAlt,
	faSearch,
	faCircleNotch,
	faThumbsDown,
	faEdit,
	faBackward,
	faHandPeace,
	faThumbsUp,
	faStar as faStarFas,
	faPaperPlane,
	faEye,
	faEyeSlash,
	faHourglassHalf,
	faExclamation,
	faStepForward,
	faUsers,
	faHome,
	faTags,
	faCog,
	faBomb,
	faMinus,
	faFileWord,
	faFileExcel,
	faLock,
	faExclamationTriangle,
	faListAlt,
	faReply,
	faAngleRight,
	faAngleLeft,
	faAd
} from '@fortawesome/free-solid-svg-icons';
import {
	faStar as faStarFar,
	faSave,
	faQuestionCircle,
	faSmile
} from '@fortawesome/free-regular-svg-icons';
import '../sass/style.scss';

import './shared/select2';
import './shared/tooltip';
import './shared/messages';
import $ from 'jquery';

config.searchPseudoElements = true;
library.add(
	faPlus,
	faCheck,
	faTimes,
	faBars,
	faStarFar,
	faStarFas,
	faLightbulb,
	faHandsHelping,
	faUserCircle,
	faSave,
	faQuestionCircle,
	faCloudUploadAlt,
	faFilePdf,
	faFileImage,
	faFileWord,
	faFileExcel,
	faFileAlt,
	faSearch,
	faCircleNotch,
	faThumbsUp,
	faThumbsDown,
	faEdit,
	faBackward,
	faHandPeace,
	faPaperPlane,
	faEye,
	faEyeSlash,
	faHourglassHalf,
	faExclamation,
	faStepForward,
	faUsers,
	faHome,
	faTags,
	faCog,
	faBomb,
	faMinus,
	faLock,
	faExclamationTriangle,
	faSmile,
	faListAlt,
	faReply,
	faAngleRight,
	faAngleLeft,
	faAd
);
dom.watch();

/**
 * Utility function to serialize a form to an object.
 */
$.fn.serializeObject = function () {
    var o = {};
    var a = this.serializeArray();
    $.each(a, function () {
		var name = this.name;
		if(name.indexOf('[]') === name.length - 2) {
			name = name.substr(0, name.length - 2);
		}

        if (o[name] !== undefined) {
            if (!o[name].push) {
                o[name] = [o[name]];
            }      
            o[name].push(this.value || '');
        } else {
            o[name] = this.value || '';
        }
    });
    return o;
};

$.fn.addNotice = function(notice, type) {
	var $message = $('<div class="alert alert-' + type + ' alert-dismissible fade show" role="alert"></div>');
	$message.append(notice);
	$message.append('<button type="button" class="close" data-dismiss="alert" aria-label="Close"><i class="fa fa-xs fa-times"></i></button>');
	$(this).append($message);
}