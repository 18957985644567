import $ from 'jquery';

$(function() {
	$(document).allTooltips();
});

$.fn.allTooltips = function() {
	$('[data-toggle="tooltip"]').tooltip({
		html: true,
		trigger: 'hover'
	});
}