import $ from 'jquery';


/**
 * Select a conversation and load its messages.
 */
$(document.body).on('click', '.conversation', function() {
	if($(this).hasClass('selected')) return;

	$('.conversations').children().removeClass('selected');
	$(this).addClass('selected');
	var token = $(this).data('token');
	getMessages(token, 0);

	$('#newMessageForm').find(':input').prop('disabled', false);
});


/**
 * Set the height of the messages box and select the first conversation on page load.
 */
$('body.messages-all').ready(function() {
	var height = $('.fixed-page-content').height() - $('.messages-wrapper .new-message').outerHeight(true) - $('.messages-wrapper h1').outerHeight(true);
	height = (height > 600 )? height:600;

	$('.messages').css('height', height);

	if($('.conversations').children('.selected').length > 0) {
		var $selected = $('.conversations').children('.selected').first();
		$selected.removeClass('selected');
		$selected.trigger('click');
	} else {
		$('.conversation').first().trigger('click');
	}
});


/**
 * Send new messages as AJAX.
 */
$(document.body).on('submit', '#newMessageForm', function(e) {
	e.preventDefault();
	var data = $(this).serializeObject();
	$(this).find(':input[name="message"]').val('');

	if(!data.message) return;

	$.post({
		url: '/messages',
		data: data,
		success: function() {
			getMessages(data.token, 1);
		},
		error: function(req) {
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	})
});


/**
 * Approve an idea access request
 */
$(document.body).on('click', '.message-approve', function(e) {
	var $wrapper = $(this);
	var url = $(e.target).hasClass('approve') ? '/matches/approve' : '/matches/deny';

	$.post({
		url: url,
		data: {
			token: $wrapper.data('token')
		},
		success: function() {
			$wrapper.remove();
			getMessages($wrapper.data('token'), 1);
		},
		error: function(req) {
			$('.notices').addNotice(req.responseJSON.message, 'danger');
		}
	});
});


$(document.body).ready(function() {
	updateUnread();
});


/**
 * Get messages and populate the messages box.
 * 
 * @param {string} token A JWT security token
 * @param {int} limit If greater than zero, a number of messages (up to limit) will be returned ordered from newest to oldest.
 */
function getMessages(token, limit) {
	$.get({
		url: '/messages/get_messages',
		data: {
			token: token,
			limit: limit
		},
		success: function(res) {
			if(!limit) {
				$('.messages .message').remove();
			}

			var $messages = $('.messages');
			if(res.messages && res.messages.length > 0) {
				$messages.find('.no-messages').hide();
		
				res.messages.forEach(function(element) {
					var $message = $('<div class="message' + (element.is_mine ? ' mine' : '') + '"></div>');
					if(!element.is_mine) {
						$message.append('<small>' + element.sender_name + '</small>');
					}
					$message.append('<div class="message-inner" data-toggle="tooltip" data-placement="bottom" title="' + element.time_sent + '">' + element.message + '</div>');
					$messages.append($message);
					$('.new-message input[name="token"]').val(token);
				});

				if(res.approve) {
					var $approve = $messages.find('.message-approve').detach();
					$approve.find('.user-name').text(res.sender_name);
					$approve.attr('data-token', token);
					$messages.append($approve);
					$approve.show();
				}
		
				$(document).allTooltips();
				$messages.scrollTop($messages.prop('scrollHeight'));
				updateUnread();
			} else {
				$('.messages .no-messages').show();
			}
		}
	});
}


/**
 * Update the unread messages notification
 */
function updateUnread() {
	$('.main-menu .messages-item').find('.has-unread').remove();

	$.get({
		url: '/messages/has_unread',
		success: function(res) {
			if(res.has_unread) {
				$('.main-menu .messages-item').append('<span class="has-unread"><i class="fas fa-exclamation"></i></span>');
			}
		}
	});
}