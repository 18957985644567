import $ from 'jquery';
import 'select2';

$(function() {
	$(document).allSelect2();

	$(document.body).on('select2:select', '.header-body .actions', function(e) {
		var action = $(e.target).data('action');
		var value = $(e.target).val();

		var params = $.getQueryParameters();
		params[action] = value;
		params = $.param(params);

		var base = [location.protocol, '//', location.host, location.pathname].join('');
		location.href = params ? [base, '?', params].join('') : base;
	});

	$(document.body).on('select2:clear', '.header-body .actions', function(e) {
		var action = $(e.target).data('action');

		var params = $.getQueryParameters();
		delete params[action];
		params = $.param(params);

		var base = [location.protocol, '//', location.host, location.pathname].join('');
		location.href = params ? [base, '?', params].join('') : base;
	});
});

$.fn.allSelect2 = function(callback) {
	var container = this;

	container.find('select').each(function() {
		var select = $(this);
		var settings = {
			dropdownAutoWidth: true,
			width: '100%',
			tags: (select.data('tags') === 'true'),
			theme: 'bootstrap4',
			dropdownParent: $(this).parent(),
			language: {
				noResults: function () {
					return $('body').hasClass('lang-sv') ? 'Inga träffar' : 'No results found';
				},
				searching: function () {
					return $('body').hasClass('lang-sv') ? 'Söker…' : 'Searching…';
				}
			}
		};

		if(select.data('search') !== true) settings.minimumResultsForSearch = -1;
		if(select.data('width')) settings.width = select.data('width');

		select.select2(settings);

		if(callback && typeof(callback) === 'function') {
			setTimeout(function() {
				callback(select);
			}, 0);
		}
	});
};

$.extend({
	getQueryParameters: function (str) {
		if(!str && !location.search) return {};

		return (str || document.location.search).replace(/(^\?)/, '').split("&").map(function (n) {
			return n = n.split("="), this[n[0]] = n[1], this;
		}.bind({}))[0];
	}
});